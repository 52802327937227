/* @import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;0,700;1,900&display=swap"); */
body {
  font-family: Roboto, sans-serif;
  background-image: url("../public/assets/images/bg.jpg");
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-attachment: fixed;

  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeSpeed;
  scroll-behavior: smooth;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body,
html {
  width: 100%;
  height: 100%;
}

.w-full {
  width: 100%;
}

a {
  cursor: pointer !important;
}

body .beno-button {
  color: white;
  margin: 0 auto !important;
  min-width: 200px;
}

body .swal2-container {
  z-index: 999999 !important;
}

.MuiDialog-root {
  z-index: 99 !important;
}

.swal2-close:focus {
  box-shadow: none !important;
}
